



































import axios from "axios";
import Vue from "vue";
import Nav from "@/components/Nav.vue"; // @ is an alias to /src

export default Vue.extend({
  data() {
    return {
      authenticated: false,
      unlockAvailable: false,
      password: "",
    };
  },
  components: {
    Nav,
  },
  computed: {
    locked(): boolean {
      return !this.authenticated;
    },
    user(): any {
      return this.$store.state.user;
    },
    settings(): any {
      return this.$store.state.settings;
    },
  },
  methods: {
    unlock(e): void {
      e.preventDefault();
      if (this.unlockAvailable === false) return;
      if (this.password === this.settings.master_password) {
        localStorage.setItem("master_token", this.settings.master_password);
        this.authenticated = true;
        this.$store
          .dispatch("getUser")
          .then((response) => {
            this.$router.push("/events").catch((e) => {
              //
            });
          })
          .catch((e) => {
            console.error(e);
            this.$router.push("/").catch((e) => {
              //
            });
          });
      }
      this.password = "";
    },
  },
  mounted() {
    this.authenticated = false;

    this.$store
      .dispatch("getSettings")
      .then(() => {
        this.unlockAvailable = true;
        const masterToken = localStorage.getItem("master_token");
        if (masterToken === this.settings.master_password) {
          this.$store.dispatch("getUser");
          this.authenticated = true;
          this.$router.push("/events").catch((e) => {
            //
          });
        }
      })
      .catch(console.error);
  },
});
